import React from "react";
import { Link } from "gatsby";

import BannerMap from "./bannerMap";
import t from "../locale";

const SmallBanner = ({
  title,
  aditionalBreadcrump,
  aditionalBreadcrumpLink,
  smallTitle,
  lang,
}) => (
  <div className="banner banner--small">
    <div className="banner__background">
      <div className="background__map">
        <BannerMap />
      </div>
    </div>
    <div className="container container--big">
      <div className="row banner__content-holder">
        <div className="col-12 banner__holder">
          <div className="holder__line"></div>
          <div className="holder__line"></div>
          <div className="holder__line"></div>
          <div className="holder__line"></div>
          <div className="holder__line"></div>
          <div className="holder__line"></div>
          <div className="holder__line"></div>
          <div className="banner__content">
            <h1
              className={`banner__title color--white mb-20 ${
                smallTitle ? " banner__title--small-title" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <div className="banner__breadcrumbs">
              <Link
                className="breadcrumbs__item color-btn--white"
                to={t("/", lang)}
              >
                {t("Home", lang)}
              </Link>
              <span className="breadcrumbs__separator color--white">/</span>
              {aditionalBreadcrump && (
                <>
                  <Link
                    className="breadcrumbs__item color-btn--white"
                    to={aditionalBreadcrumpLink}
                  >
                    {aditionalBreadcrump}
                  </Link>
                  <span className="breadcrumbs__separator color--white">/</span>
                </>
              )}
              <p className="color--secondary breadcrumbs__last bold {smallTitle}">
                {title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SmallBanner;
