import React from "react";

import Layout from '../../components/layout';
import SmallBanner from '../../components/smallBanner';


const SingleOffer = () => (
  <Layout seoTitle="EPAL 3 HT">
    <SmallBanner 
        title="EPAL 3 HT" 
        aditionalBreadcrump="Oferta"
        aditionalBreadcrumpLink="/oferta/"
    />
    <section className="single-offer-info">
        <div className="container">
            <div className="row single-offer-info__holder">
                <div className="col-12 col-lg-4 single-offer-info__image">
                    <img
                        src={require("../../assets/img/palete.png")}
                        alt="Paletten"
                    />
                </div>
                <div className="col-12 col-lg-8">
                    <div className="single-offer-info__left-padding">
                        <p className="mb-50">
                            13 desek z drewna wysokiej jakości, 9 wsporników z drewna rozdrobnionego bądź 
                            litego, 84 gwoździe, wykonanie zgodne z 
                            Regulaminem Technicznym EPAL.
                        </p>
                        <p className="bold mb-30">
                            Parametry
                        </p>
                        <div className="single-offer-info__parameters">
                            <div className="parameters__item">
                                <img 
                                    className="item__icon"
                                    src={require('../../assets/img/dimentions.svg')} 
                                    alt="Wymiary"
                                />
                                <div className="item__content">
                                    <p className="bold">
                                        Wymiary
                                    </p>
                                    <p>
                                        1200 x 1000 x 144 mm
                                    </p>
                                </div>
                            </div>
                            <div className="parameters__item">
                                <img 
                                    className="item__icon"
                                    src={require('../../assets/img/weight.svg')} 
                                    alt="Waga"
                                />
                                <div className="item__content">
                                    <p className="bold">
                                        Waga
                                    </p>
                                    <p>
                                        ok. 30kg
                                    </p>
                                </div>
                            </div>
                            <div className="parameters__item">
                                <img 
                                    className="item__icon"
                                    src={require('../../assets/img/lifting-capacity.svg')} 
                                    alt="Wymiary"
                                />
                                <div className="item__content">
                                    <p className="bold">
                                        Udźwig
                                    </p>
                                    <p>
                                        1500 kg
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="single-offer-content">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
                    <h2 className="color--main mb-40">
                        Cechy
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-4 single-offer-content__single-image">
                    <img
                        src={require("../../assets/img/single-offer2.jpg")}
                        alt="Firm info"
                    />
                </div>
                <div className="col-12 col-lg-6 col-xl-8 col-xxl-8 ">
                    <div className="single-offer-content__left-padding">
                        <p>
                            Praca European Pallet Association e.V., komitetów narodowych oraz licencjobiorców tworzy stabilną podstawę dobrze działającego 
                            otwartego poolu paletowego i umożliwia globalną wymianę nośników ładunku EPAL. Na całym świecie w obiegu znajduje się 
                            ponad 450 milionów palet EPAL, które używane są w różnych branżach i bez których trudno wyobrazić sobie funkcjonowanie 
                            przemysłu i logistyki. Skrupulatne dopasowywanie się do potrzeb rynku sprawiło, że w całej Europie praktycznie wszystkie 
                            systemy magazynowania oraz transportu dopasowane są do standardowych wymiarów. Stała gwarancja jakości oraz wysokiej 
                            akości materiały oferują bezproblemowe i bezpieczne stosowanie nośników ładunku EPAL dla użytkowników i w transporcie. 
                            Poprzez ich wielokrotne stosowanie, w dużej mierze chronione są ekologiczne i ekonomiczne zasoby .   
                        </p>
                        <p>
                            EPAL w owalu na lewym i prawym wsporniku narożnym (od 08/2013 r.) Oznakowanie IPPC, kod kraju, numer 
                            rejestracyjny krajowego urzędu ds. ochrony roślin, obróbka termiczna (heat treatment), numer 
                            licencyjny-rokmiesiąc na wsporniku środkowym. Klamry kontrolne EPAL. 
                        </p>
                        <p>
                            Cztery wejścia i dwustronnie fazowane deski dolne umożliwiają wysoce efektywną obsługę przy produkcji, magazynowaniu i transporcie 
                            w całym łancuchu dostawczym. Kompatybilne ze wszystkimi (powszechnie stosowanymi) nośnikami ładunku, pojazdami 
                            przemysłowymi i systemami magazynowania.
                        </p>
                        <p>
                            Jakość jest kluczowym kryterium niezawodnego i bezpiecznego stosowania europalet EPAL w łańcuchach dostawczych 
                            międzynarodowo działających przedsiębiorstw. Otwarty pool europalet EPAL jako jedyny na świecie podlega stałym 
                            i niezależnym kontrolom jakości przeprowadzanym przez jedną z najbardziej renomowanych na świecie organizacji 
                            kontrolnych Bureau Veritas.
                        </p>
                        <img
                            src={require("../../assets/img/single-offer1.jpg")}
                            alt="Firm info"
                        />
                        <ul>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </li>
                            <li>
                                Curabitur quis magna et est porta pulvinar vitae eget erat.
                            </li>
                            <li>
                                Nullam pretium urna ut ex dignissim sagittis.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </Layout>
);

export default SingleOffer;
